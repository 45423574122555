.container {
  background-color: #f4edf9;
  padding-bottom: 5rem;
  padding-top: 4rem;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  /* flex-wrap: wrap; */
  gap: 2rem;
  padding-left: 2rem;
  padding-right: 2rem;
}

.faq_wrapper {
  height: auto;
  width: 60%;
}

.faq_wrapper h3 {
  font-size: 2.5rem;
  font-weight: 700;
  line-height: 3.4375rem;
  height: 5%;
  padding: 0 1rem;
}

.collapsible_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 1rem;
  gap: 1rem;
  height: 90%;
  width: 100%;
}

.collapsible_container {
  border: 0.5px solid #dfdfdf;
  background: #fff;
  box-shadow: 0px 5px 16px 0px rgba(0, 0, 0, 0.06);
  padding: 2rem;
  width: 100%;
  margin: auto;
}

.collapsible_header {
  font-size: 1.375rem;
  font-weight: 500;
  line-height: 1.75rem;
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  margin: 0;
}

.plus,
.minus {
  width: 2rem;
  height: 2rem;
  color: #fff;
  cursor: pointer;
  border-radius: 10px;
  font-size: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
}

.minus {
  background-color: #f4edf9;
  font-weight: bold;
}

.answer {
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 150%;
  margin-right: 2rem;
}

.carousels {
  width: 31rem;
  height: 74.0625rem;
  margin: 0 1rem;
}

@media screen and (max-width: 900px) {
  .container {
    flex-wrap: wrap;
    padding-right: unset;
    padding-left: unset;
    gap: 1rem;
  }

  .collapsible_container {
    width: 100%;
    min-width: unset;
  }

  .faq_wrapper,
  .faq_wrapper h3,
  .collapsible_wrapper,
  .carousels {
    height: auto;
    width: 100%;
  }
}
