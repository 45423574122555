.menu {
  width: 100%;
  height: calc(100vh - 4.5rem);
  padding: 1rem 2rem 9.375rem 2rem;
  overflow: auto;
  border-right: 1px solid #dbdfe4;
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  position: fixed;
  left: 0;
  top: 4.5rem;
  z-index: 10000;
  display: none;
}

.pages {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  gap: 0.3rem;
  padding: 1rem;
}

.nav {
  text-decoration: none;
  color: inherit;
  display: flex;
  gap: 1rem;
  align-items: center;
  padding: 0.5rem;
  width: 100%;
  border-radius: 0.25rem;
  font-feature-settings:
    'clig' off,
    'liga' off;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.5rem;
}

.active {
  background: #fff;
  color: black;
}

.contact_us_btn {
  font-size: 1.125rem;
  font-weight: 600;
}

.membership {
  position: relative;
  padding: 10px;

  overflow: hidden;
}

.membership::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background-color: #ffffff;
  transition: width 0.2s ease;
  z-index: -1;
  border-radius: 4px;

}

.membership:focus::after {
  width: 100%;

}

.membership:focus {
  color: black !important;
}

@media screen and (max-width: 920px) {
  .menu {
    display: unset;
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 4.5rem;
    animation: MoveIn;
    animation-duration: 0.4s;
  }

  @keyframes MoveIn {
    from {
      left: -100%;
    }

    to {
      left: 0;
    }
  }

  .hide_mobile_menu {
    left: -100%;
    animation: MoveOut;
    animation-duration: 0.7s;
  }

  @keyframes MoveOut {
    from {
      left: 0;
    }

    to {
      left: -100%;
    }
  }
}