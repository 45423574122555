.wrapper {
  position: relative;
}

.arrow_up {
  width: 4.375rem;
  height: 4.375rem;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 90%;
  margin: auto;
  cursor: pointer;
}

.bottoms {
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 2rem;
  padding-left: 5rem;
  padding-right: 5rem;
}

.socials > img {
  width: 1.5rem;
  height: 1.5rem;
}

.group {
  height: 16rem;
}

.xlogo{
  width: 1.3rem;
  height: 1.3rem;
}

@media screen and (max-width: 900px) {
  .bottoms {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .tops {
    flex-direction: column;
  }

  .mobile_algin,
  .group {
    align-self: flex-start;
    padding: 1rem;
  }

  .group {
    height: unset;
    gap: 1rem;
  }

  .arrow_up {
    bottom: 97%;
  }

  .socials{
    margin-bottom: unset;
  }
}
