.container {
  height: 4.5rem;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.container ul li {
  cursor: pointer;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.875rem;
  color: #fff;
}

.header_btn {
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.5rem;
  width: 10.5rem;
  height: 2.5rem;
}

.hamburger {
  display: none;
  cursor: pointer;
}

.bar1,
.bar2,
.bar3 {
  width: 35px;
  height: 5px;
  background-color: #fff;
  margin: 6px 0;
  transition: 0.4s;
}

.change .bar1 {
  transform: translate(0, 11px) rotate(-45deg);
}

.change .bar2 {
  opacity: 0;
}

.change .bar3 {
  transform: translate(0, -11px) rotate(45deg);
}

.headerLink {
  position: relative;
  padding: 10px;
  color: white;
  overflow: hidden;
}

.headerLink::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background-color: #662d91;
  transition: width 0.2s ease;
  z-index: -1;
  border-radius: 4px;
}

.headerLink:hover::after {
  width: 100%;

}

@media screen and (max-width: 900px) {
  .container ul {
    display: none !important;
  }

  .img_container {
    width: 30%;
  }

  .header_btn {
    font-size: 0.75rem;
    width: 8.5rem;
  }

  .hamburger {
    display: inline-block;
  }
}