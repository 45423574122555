.input {
    background-color: var(--input);
    border: 1px solid rgba(127, 145, 168, 0.4);
    border-radius: 4px;
    width: 100%;
    outline: none;
    padding: 0 0.625rem;
    height: 2.5rem;
    color: var(--text);
    font-size: 0.875rem;
    font-weight: 600;
}

.input_label {
    font-weight: 400;
    font-size: 1rem;
    color: var(--tintText);
    margin-bottom: 0.5rem;
}

.input::placeholder {
    font-style: normal;
    font-weight: normal;
    font-size: 0.875rem;
    line-height: 1.375rem;
    color: #AAB7C6;
}

.editable {
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.5rem;
}

.valid_errormessage{
      font-size: 12px;
    padding-top: 3px;
    color: red;
} 