.container {
  height: 100vh;
  width: 100%;
  background:
    linear-gradient(0deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.3) 100%),
    linear-gradient(0deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.3) 100%),
    linear-gradient(0deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.3) 100%),
    linear-gradient(0deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.3) 100%),
    linear-gradient(0deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.3) 100%),
    url(../../assets/images/bookings_background.png),
    lightgray 50% / cover no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  overflow: hidden;
}

.hero {
  height: 100%;
  max-width: calc(100vw - 36%);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #fff;
  text-align: center;
  margin-right: auto;
}

.hero > h2 {
  font-size: 3rem;
  font-weight: 700;
  line-height: 118%;
}

.hero > p {
  font-size: 1.3rem;
  font-weight: 500;
  line-height: 2.5rem;
}

.slide_wrapper {
  margin-left: auto;
  width: 36%;
  height: 100vh;
  border-radius: 0rem 0rem 0rem 1.25rem;
  background: rgba(0, 0, 1, 0.8);
  padding-top: 0.5rem;
  padding-bottom: 8rem;
  position: absolute;
  top: 4.5rem;
  right: 0;
  overflow: auto;
  scroll-behavior: smooth;
}

.card1 {
  background:
    url(../../assets/images/bookings_card/active.jpg),
    lightgray 50% / cover no-repeat;
}

.card2 {
  background:
    url(../../assets/images/bookings_card/swim.jpg),
    lightgray 50% / cover no-repeat;
}

.card3 {
  background:
    url(../../assets/images/bookings_card/arcade.jpg),
    lightgray 50% / cover no-repeat;
}

.card4 {
  background:
    url(../../assets/images/bookings_card/beauty.jpg),
    lightgray 50% / cover no-repeat;
}

.card {
  display: flex;
  position: relative;
  width: 92%;
  height: calc((100vh - 4.5rem) / 2);
  border-radius: 1.25rem;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  margin: auto;
  margin-bottom: 1rem;
}

.card_text {
  align-self: flex-end;
  height: 4.125rem;
  width: 100%;
  padding: 0.8125rem 1.25rem;
  color: #fff;
  font-size: 1.5rem;
  font-weight: 600;
  border-bottom-left-radius: 1.25rem;
  border-bottom-right-radius: 1.25rem;
  background: rgba(0, 0, 0, 0.3);
  margin-bottom: 0;
  backdrop-filter: blur(4px);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card_overLay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  background-color: rgba(0, 0, 0, 0.5);
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  color: #fff;
  padding: 1rem;
}

.card:hover {
  background-size: 130%;
}

.card:hover .card_overLay {
  opacity: 1;
}

.card:hover .card_text {
  opacity: 0;
}

.card_overLay > h3 {
  font-size: 1rem;
  font-weight: 700;
}

.card_overLay > h3 {
  font-size: 0.8rem;
  font-weight: 700;
  line-height: 2.1875rem;
}

.card_overLay > p {
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5rem;
}

.card_btn {
  min-width: 10.5rem;
  min-height: 2.525rem;
  align-self: center;
  font-weight: bold;
}

.scroll_wrapper {
  position: absolute;
  right: 0;
  bottom: 0;
  margin-left: auto;
  text-align: center;
  width: 36%;
  z-index: 10;
}

.scroll_image {
  width: 2.125rem;
  margin: auto;
  cursor: pointer;
  /* margin-top: 5rem; */
}

.scroll_text {
  font-family: Poppins;
  font-size: 0.8125rem;
  font-weight: 400;
  line-height: 120%;
  color: #fff;
}

@media screen and (max-width: 920px) {
  .container {
    overflow: auto;
  }

  .slide_wrapper,
  .card {
    width: 100%;
  }

  .hero {
    margin-left: unset;
    max-width: unset;
  }

  .hero > h2 {
    font-size: 2.5rem;
  }

  .hero > p {
    font-size: 1rem;
  }

  .slide_wrapper {
    position: unset;
    height: auto;
  }

  .scroll_wrapper {
    width: 100%;
  }

  .card_overLay > h3 {
    font-size: 1rem;
    font-weight: 700;
  }

  .card_overLay > h3 {
    font-size: 0.8rem;
    font-weight: 700;
    line-height: 1.1875rem;
  }

  .card_overLay > p {
    font-size: 0.8rem;
    font-weight: 500;
    line-height: 1.25rem;
  }
}
