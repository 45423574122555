.dialog {
 
}

.wrapper {
  font-feature-settings:
    'clig' off,
    'liga' off;
    padding: 6rem 2rem;
}

.wrapper > h3 {
  font-size: 1.5rem;
  font-weight: 600;
}

.wrapper > p {
  font-size: 1rem;
  font-weight: 400;
}
