.home {
  background-color: #fff;
}

.right_bar {
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 1000;
  width: 4.375rem;
  background: #fff;
  backdrop-filter: blur(6px);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 14.925rem;
}

.right_bar > div > * {
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.2rem;
}

.right_bar_items {
  margin: 0;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 0.9375rem;
}

.hero_wrapper {
  background-image: url('../../assets/images/home_hero/1.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  width: 100%;
  animation: Change 15s ease-in-out infinite alternate;
}

.hero {
  position: relative;
  height: 100%;
  width: 100%;
  text-align: center;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.5);
}

@keyframes Change {
  0% {
    background-image: url('../../assets/images/home_hero/1.jpg');
  }
  25% {
    background-image: url('../../assets/images/home_hero/2.jpg');
  }
  50% {
    background-image: url('../../assets/images/home_hero/3.jpg');
  }
  100% {
    background-image: url('../../assets/images/home_hero/4.jpg');
  }
}

.scroll_wrapper {
  position: absolute;
  right: 0;
  left: 0;
  /* top: 0; */
  bottom: 0;
  margin: auto;
}

.scroll_image {
  width: 2.125rem;
  margin: auto;
  cursor: pointer;
  /* margin-top: 5rem; */
}

.scroll_text {
  font-family: Poppins;
  font-size: 0.8125rem;
  font-weight: 400;
  line-height: 120%;
}

.hero_child {
  padding-top: 5rem;
}

.hero_child > h2 {
  font-size: 4.375rem;
  font-weight: 700;
  line-height: 118%;
  bottom: -1em;
}

.animated_title {
  position: relative;
  bottom: -1em;
  opacity: 0;
  animation: move-text 0.5s forwards;
}

@keyframes move-text {
  0% {
    bottom: -0.2em;
    opacity: 1;
  }

  100% {
    bottom: 0;
    opacity: 1;
  }
}

.hero_child > p {
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 2.5rem;
}

.what_we_offer {
  padding: 6.25rem;
  background: #1c201f;
  color: #fff;
}

.what_we_offer h3 {
  font-size: 3rem;
  font-weight: 600;
}

.what_we_offer p {
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 2.1875rem;
}

.home_grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: repeat(21.5rem, 2);
  gap: 1rem;
  height: 44.25rem;
  width: 100%;
}

.grid1 {
  background:
    linear-gradient(0deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.4) 100%),
    url('../../assets/images/activity/Dine.jpg'),
    lightgray -354.01px 0px / 294.762% 100% no-repeat;
  grid-row: span 2;
}

.grid2 {
  background:
    linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%),
    url('../../assets/images/activity/Swim.jpg'),
    lightgray -354.01px 0px / 294.762% 100% no-repeat;
}

.grid3 {
  background:
    linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%),
    url('../../assets/images/bookings_card/arcade.jpg'),
    lightgray -354.01px 0px / 294.762% 100% no-repeat;
}

.grid4 {
  background:
    linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%),
    url('../../assets/images/activity/Dance.png'),
    lightgray -354.01px 0px / 294.762% 100% no-repeat;
}

.grid5 {
  background:
    linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%),
    url('../../assets/images/activity/Active.jpg'),
    lightgray -354.01px 0px / 294.762% 100% no-repeat;
}

.grid2,
.grid3,
.grid4,
.grid5 {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.home_grid > div {
  display: flex;
  position: relative;
}

.grid_text {
  align-self: flex-end;
  padding-left: 3rem;
  padding-bottom: 1rem;
  color: #fff;
  font-size: 2rem;
  font-weight: 600;
}

.grid_overLay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  background-color: rgba(0, 0, 0, 0.5);
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  padding: 1.2rem;
}

.home_grid > div:hover .grid_overLay {
  opacity: 1;
}

.home_grid > div:hover .grid_text {
  opacity: 0;
}

.grid:hover {
  background-size: 170%;
}

.grid_overLay > h3 {
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 2.1875rem;
}

.grid_overLay > p {
  font-size: 1rem;
  font-weight: 500;
  line-height: 2rem;
}

.grid_btn {
  min-width: 10.5rem;
  min-height: 3.125rem;
  align-self: center;
}

.contact {
  display: flex;
}

.contact > div {
  min-height: 40rem;
}

.contact_form {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  background:
    linear-gradient(0deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.8) 100%),
    url('../../assets/images/contact/contact.jpg'),
    lightgray -354.01px 0px / 294.762% 100% no-repeat;
  background-position: center;
  background-size: cover;
}

.contact_input {
  color: #212529 !important;
  width: 27.1875rem;
}

.contact_form > div > h3 {
  font-size: 3.75rem;
  font-weight: 700;
  line-height: 2.125rem;
  margin-bottom: 1.5rem;
}

.contact_form > div > p {
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.75rem;
}

/* Slideup animations */
.slid_up_container {
  margin: 4rem !important;
}

.item {
  position: relative;
  width: 24.83313rem;
  height: 28.625rem;
  background-color: #f4edf9;
  overflow-y: hidden;
  box-shadow: 0.1rem 0.1rem 1rem rgba(0, 0, 0, 0.1);
}

img {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}

.item__overlay {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  padding: 1.875rem 1.25rem;
  height: 100%;
  position: absolute;
  width: 100%;
  top: 0;
  transition: transform 300ms;
  background-color: #f4edf9;
  transform: translate3d(0, 100%, 0);
  text-align: center;
}

.item__overlay h3 {
  font-size: 1.5rem;
  font-weight: 700;
}

.item__overlay p {
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 2.25rem;
  color: #777;
}

.item a {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.item a:hover,
.item a:focus {
  ~ .item__overlay {
    transform: translate3d(0, 0, 0);
  }
}

.plan {
  margin: 4rem;
}

.plan h2 {
  font-size: 3rem;
  font-weight: 600;
  line-height: 3.4375rem;
}

.plan p {
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 2.1875rem;
}

.plans_card_container {
  width: 24.8125rem;
  height: 31.875rem;
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 4rem 4rem 4rem;
}

.plans_card_container h5 {
  font-size: 1.5rem;
  font-weight: 600;
}

.plans_card_container p {
  font-size: 1.75rem;
  font-weight: 700;
  padding-bottom: 2rem;
}

.plans_card_container ul {
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.875rem;
  text-align: start;
  list-style: none;
  padding-left: 0;
}
.plans_card_container ul li {
  margin-bottom: 1.5rem;
}

.offer_container {
  position: relative;
  height: 37.5rem;
  margin: 4rem;
  overflow: hidden;
}

.offer_image {
  display: block;
  width: 100%;
  height: 100%;
}

.offer_overlay {
  position: absolute;
  bottom: 0;
  left: 100%;
  right: 0;
  text-align: start;
  overflow: hidden;
  height: 100%;
  transition: 0.5s ease;
}

.offer_container:hover .offer_overlay {
  width: 50%;
  left: 50%;
}

/* .offer_text {
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  white-space: nowrap;
} */

.offer_text {
  background: rgba(244, 237, 249, 0.97);
  padding: 6rem;
  text-align: start;
  height: 100%;
}

.offer_text h4 {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 1rem;
}

.offer_text h5 {
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 2.1875rem;
}

.offer_text p {
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 2.25rem;
  margin-bottom: 1rem;
}

@media screen and (max-width: 1024px) {
  .hero_child h2 {
    font-size: 3rem;
  }

  .hero_child p {
    font-size: 1.2rem;
  }

  .what_we_offer {
    padding: 6.25rem 1rem;
  }

  .home_grid {
    grid-template-columns: 1fr;
    grid-row: 1fr;
    height: auto;
  }

  .home_grid > div {
    height: 21.5rem;
    width: 100%;
  }

  .what_we_offer h3 {
    font-size: 2rem;
  }

  .what_we_offer p {
    font-size: 1rem;
  }

  .slid_up_container,
  .plan {
    margin: 4rem 2rem;
  }

  .offer_container {
    margin: 4rem 1rem;
  }

  .offer_text {
    padding: 1rem;
  }

  .offer_container:hover .offer_overlay {
    width: 100%;
    left: 0;
  }

  .grid_overLay > h3 {
    font-size: 1.1rem;
  }

  .contact {
    flex-direction: column;
  }

  .map {
    min-height: unset !important;
  }

  .tops {
    align-items: flex-start;
  }
}
