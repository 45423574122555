@font-face {
  font-family: 'Lato';
  src: url('./assets/fonts/Lato/Lato-Regular.ttf');
}

@font-face {
  font-family: 'Raleway';
  src: url('./assets/fonts/Raleway/Raleway-VariableFont_wght.ttf');
}

@font-face {
  font-family: 'Inter';
  src: url('./assets/fonts/Inter/Inter-VariableFont_slnt\,wght.ttf');
}

@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/Poppins/Poppins-Light.ttf');
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Raleway, Sans-serif, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

.cursor-pointer{
  cursor: pointer;
}
