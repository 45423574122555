.text_area {
    background-color: var(--input);
    border: 1px solid rgba(127, 145, 168, 0.4);
    border-radius: 4px;
    width: 100%;
    outline: none;
    padding: 0.625rem;
    color: var(--text);
    font-size: 14px;
    font-weight: 600;
    line-height: 1.5rem;
    resize: none;
    font-family: Averta,  Sans-serif;
}

.text_area_label {
    font-weight: 400;
    font-size: 1rem;
    color: var(--tintText);
    margin-bottom: 0.5rem;
}

.text_area::placeholder {
    font-style: normal;
    font-weight: normal;
    font-size: 0.875rem;
    line-height: 1.375rem;
    color: #AAB7C6;
}

.valid_errormessage{
      font-size: 12px;
    padding-top: 3px;
    color: red;
} 