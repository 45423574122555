.wrapper {
  height: 100vh;
  width: 100%;
  background-image: url(../../assets/images/dine_bacground/1.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  animation: Change 15s ease-in-out infinite alternate;
}

.container {
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  overflow: auto;
  position: relative;
  overflow: hidden;
}
.grid_btn {
  min-width: 10.5rem;
  min-height: 3.125rem;
  align-self: center;
}

@keyframes Change {
  0% {
    background-image: url(../../assets/images/dine_bacground/1.jpg);
  }
  50% {
    background-image: url(../../assets/images/dine_bacground/2.jpg);
  }
  100% {
    background-image: url(../../assets/images/dine_bacground/3.png);
  }
}

.hero {
  height: 100%;
  max-width: calc(100vw - 36%);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #fff;
  text-align: center;
  margin-right: auto;
}

.hero > h2 {
  font-size: 3rem;
  font-weight: 700;
  line-height: 118%;
}

.hero > p {
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 2.5rem;
}

.slide_wrapper {
  margin-left: auto;
  width: 36%;
  height: 100vh;
  border-radius: 0rem 0rem 0rem 1.25rem;
  background: rgba(0, 0, 1, 0.8);
  padding-top: 1rem;
  padding-bottom: 8rem;
  position: absolute;
  top: 4.5rem;
  right: 0;
  overflow: auto;
}

.card1 {
  background:
    url(../../assets/images/dine_cards/Dine\ 1.jpg),
    lightgray 50% / cover no-repeat;
}

.card2 {
  background:
    url(../../assets/images/dine_cards/Dine\ 2.png),
    lightgray 50% / cover no-repeat;
}

.card {
  display: flex;
  width: 92%;
  height: calc((100vh - 4.5rem) / 2);
  border-radius: 1.25rem;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  margin: auto;
  margin-bottom: 1rem;
  cursor: pointer;
  transition: all 0.1s ease;
}

.card:hover {
  background-size: 120%;
}

.card_text {
  align-self: flex-end;
  height: 4.125rem;
  width: 100%;
  padding: 0.8125rem 1.25rem;
  color: #fff;
  font-size: 1.5rem;
  font-weight: 600;
  border-bottom-left-radius: 1.25rem;
  border-bottom-right-radius: 1.25rem;
  background: rgba(0, 0, 0, 0.3);
  margin-bottom: 0;
  backdrop-filter: blur(4px);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.scroll_wrapper {
  position: absolute;
  right: 0;
  bottom: 0;
  margin-left: auto;
  text-align: center;
  width: 33.4375rem;
  z-index: 10;
}

.scroll_image {
  width: 2.125rem;
  margin: auto;
  /* margin-top: 5rem; */
}

.scroll_text {
  font-family: Poppins;
  font-size: 0.8125rem;
  font-weight: 400;
  line-height: 120%;
  color: #fff;
}

.details {
  position: absolute;
  z-index: 20;
  top: 4.5rem;
  left: 0;
  right: 0;
  height: calc(100% - 4.5rem);
}

.details_left {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background:
    linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%),
    linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%),
    linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%),
    linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%),
    url(../../assets/images/refresh_card1.jpeg),
    lightgray 1.76px -218.847px / 58.993% 134.03% no-repeat;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.details_left > h2 {
  font-size: 3.375rem;
  font-weight: 700;
  line-height: 118%;
  color: #fff;
}

.details_left_btn {
  width: 10.5rem;
  height: 3.125rem;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.5rem;
}

.details_right {
  width: 100%;
  border-left: 1px solid #747373;
  background: hsl(0, 0%, 11%);
  color: #fff;
  padding: 2rem 4rem;
  overflow: auto;
  position: relative;
}

.details_right > p {
  display: inline-block;
  position: fixed;
  padding: 0.5rem 1rem;
  right: 5%;
  background-color: #2d2b2b;
  cursor: pointer;
}

.details_right > h2 {
  text-align: center;
  font-size: 3rem;
  font-weight: 600;
}

.menu_img {
  width: 3rem;
  height: 3rem;
}

.menu_img_null{
  width: 3rem;
  height: 3rem;
}

.menu_items {
}

.menu_items div div p {
  font-size: 1rem;
  font-weight: 500;
  text-transform: uppercase;
}

.description {
  font-weight: 400;
  line-height: 1.5rem;
  font-size: 0.8rem;
}

.price {
  font-size: 1.3rem;
  font-weight: 400;
  line-height: 1.5rem;
}

@media screen and (max-width: 920px) {
  .container {
    overflow: auto;
  }

  .slide_wrapper,
  .card {
    width: 100%;
  }

  .hero {
    margin-left: unset;
    max-width: unset;
  }

  .hero > h2 {
    font-size: 2.5rem;
  }

  .hero > p {
    font-size: 1rem;
  }

  .slide_wrapper {
    position: unset;
    height: auto;
  }

  .scroll_wrapper {
    width: 100%;
  }

  .menu_items {
    grid-template-columns: 1fr;
    gap: 1rem;
  }

  .details {
    flex-direction: column;
    position: fixed;
    overflow: auto;
  }

  .details > div {
    width: 100%;
  }

  .details_left {
    padding: 5rem 0;
    height: 25vh;
  }

  .details_right{
    padding: 2rem 1rem;
  }
}
